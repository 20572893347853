<template>
  <div class="layout">

    <!--左侧导航开始-->
    <div class="slider-menu" v-show="sliderMenu.showSliderMenu" @mouseover="sliderMenu.showScrollTop=true"
         @mouseout="sliderMenu.showScrollTop=false">

      <!--非滚动部分-->
      <div class="slider-menu-top">

        <!--公司图标-->
        <div class="logo" :style="'opacity:'+scroll.sliderTopOpacity">

          <img :src="apiUrl+'/'+config['cover']" v-if="config['cover'] && config['cover']!=''">
          <img src="../../static/images/logo.png" v-else>

          <div class="slider-menu-close" @click="sliderMenuSwitch()">
            <i class="fa fa-times-circle"></i>
          </div>
        </div>

        <!--登录用户-->
        <div class="login-user-info" :style="'opacity:'+scroll.sliderTopOpacity">
          <el-row>
            <el-col :span="8" class="user-header">
              <img :src="apiUrl+'/'+user['avatar']" v-if="user['avatar'] && user['avatar']!=''" @click="userMenu('edit-user')">
              <img src="../../static/images/header_1.png" v-else>
            </el-col>
            <el-col :span="16" class="user-info-label">
              <div>{{ user['nickName'] }}</div>
              <div @click="quit()" style="cursor: pointer;"><i class="fa fa-sign-out"></i> 安全退出</div>
            </el-col>
          </el-row>
        </div>

        <!--菜单查询-->
        <div class="menu-search" :style="'opacity:'+scroll.menuSearchOpacity">

          <el-input
              size="small"
              placeholder="请输入服务名称"
              v-model="sliderMenu.menuSearchValue"
              clearable>
            <template slot="append">

              <el-dropdown
                  @command="leftMenu"
                  size="medium"
                  trigger="click"
                  placement="bottom-end">
                <span>
                <i class="fa fa-list-ol"></i>
                <i class="fa fa-caret-down i-second"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      :command="{'act':'menuSearch'}">
                    <i class="fa fa-search"></i>查询
                  </el-dropdown-item>
                  <el-dropdown-item
                      :command="{'act':'sort'}"
                      :divided="true">
                    <i class="fa fa-sort-numeric-desc"></i>排序
                  </el-dropdown-item>
                  <el-dropdown-item
                      :command="{'act':'backtop'}"
                      :divided="true">
                    <i class="fa fa-angle-double-up"></i>置顶
                  </el-dropdown-item>
                  <el-dropdown-item
                      :command="{'act':'favorites'}"
                      :divided="true">
                    <i class="fa fa-folder-o"></i>收藏夹
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

            </template>
          </el-input>

        </div>

      </div>

      <!--功能菜单-->
      <div class="slider-menu-bar" ref="scrollSliderMenu" @mousewheel="scrollSliderMenu" id="step1">
        <div class="slider-menu-grap"></div>
        <Menubar @showTags="showTags" ref="menubar"></Menubar>
        <div class="slider-menu-grap" style="height:36px;"></div>
        <div class="slider-menu-scroll-top" title="置顶" @click="scrollTop" v-show="sliderMenu.showScrollTop">
          <i class="fa fa-angle-double-up"></i>
        </div>
      </div>

    </div>
    <!--左侧导航结束-->

    <!--右侧部分开始-->
    <div class="page-wrapper">

      <!--上部固定条目-->
      <div class="navbar" id="step2">

        <!--功能栏开关-->
        <div class="navbar-left-button slider-menu-open" @click="sliderMenuSwitch()">
          <div>
            <i class="fa fa-indent"></i>
          </div>
        </div>

        <!--欢迎文字-->
        <div class="navbar-left-button">
          <div class="full-button">
            <i class="fa fa-desktop"></i> 欢迎使用 " {{ config['company'] }} " 企业应用平台
          </div>
        </div>

        <!--用户信息管理-->
        <div class="navbar-right-button user-top-menu">
          <el-dropdown
              trigger="click"
              size="medium"
              @command="userMenu">
            <span class="el-dropdown-link">
              <div class="navbar-user-header">
              <img :src="apiUrl+'/'+user['avatar']" v-if="user['avatar'] && user['avatar']!=''">
              <img src="../../static/images/header_1.png" v-else>
              </div>
              <span class="navbar-user-title">
              {{ user['nickName'] }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="edit-user"><i class="fa fa-edit"></i> 编辑资料</el-dropdown-item>
              <el-dropdown-item command="edit-password" divided><i class="fa fa-key"></i>密码修改</el-dropdown-item>
              <el-dropdown-item command="intro" divided><i class="fa fa-map-signs"></i> 服务引导</el-dropdown-item>
              <el-dropdown-item command="logout" divided><i class="fa fa-sign-out"></i> 安全退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!--立即下载-->
        <div class="navbar-right-button" @click="downApp()">
          <div class="full-button">
            <i class="fa fa-cloud-download"></i> 立即下载
          </div>
        </div>

        <!--接口管理-->
        <!--
        <div class="navbar-right-button" @click="openApi()" >
          <div class="full-button">
            <i class="fa fa-plug"></i> 接口管理
          </div>
        </div>
        -->

        <!--数字互联-->
        <div class="navbar-right-button" @click="openLink()">
          <div class="full-button">
            <i class="fa fa-mixcloud"></i> 数字互联
          </div>
        </div>

      </div>

      <!--菜单选项标签-->
      <div class="tab-wrapper">
        <el-tabs v-model="menuTabs.activeTabName" type="card" @tab-remove="removeTab" @tab-click="clickTab">
          <el-tab-pane
              :closable="false"
              :key="menuTabs.indexTab.name"
              :label="menuTabs.indexTab.label"
              :name="menuTabs.indexTab.name"
              :url="menuTabs.indexTab.url"><span slot="label"><i class="el-icon-pie-chart"></i> 首 页</span></el-tab-pane>
          <el-tab-pane
              closable
              v-for="(item, index) in menuTabs.tabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
              :url="item.url"></el-tab-pane>
        </el-tabs>
      </div>

      <!--嵌入框架-->
      <iframe :src="'#'+menuTabs.src"
              class="main-iframe"
              :key="menuTabs.key"
              frameborder="0"
              ref="mainIframe"
              id="main-iframe"
              seamless></iframe>

      <!--版本-->
      <div class="version-wrapper" id="step3">
        <div class="float-right version"><span>Copyright <i class="fa fa-copyright"></i> 2014-2017 </span>{{ config['company'] }}<span> 出品
          &
          支持</span>
        </div>
      </div>

    </div>
    <!--右侧部分结束-->

    <!--弹出次级窗体-->
    <el-dialog :title="dialog.slaveTitle"
               :visible.sync="dialog.slaveVisible"
               :modal="true"
               :fullscreen="false">
      <iframe :src="dialog.slaveSrc" class="slave-iframe" :key="dialog.key" frameborder="0" id="slave-iframe"
              seamless></iframe>
    </el-dialog>


  </div>
</template>

<script>

// 引入自定义组件
import Menubar from '../../components/menu/menubar'
import {getConfig, logout, getLoginUser, getTableCaption} from '@/api/public.js'
import {removeToken} from '@/utils/auth'
import config from '/vue.config.js'

export default {
  name: "iframe_1",

  // 自定义组件
  components: {Menubar},
  data() {
    return {

      // 基础网址
      apiUrl: process.env.VUE_APP_BASE_API,

      // 弹出窗体
      dialog: {
        key: new Date().getTime(),
        // 从弹框
        slaveTitle: '',
        slaveVisible: false,
        slaveSrc: '',
      },

      // 左侧功能菜单开关
      sliderMenu: {
        showSliderMenu: true,
        menuSearchValue: '',
        showScrollTop: false,
      },

      // 右侧菜单选项标签
      menuTabs: {
        activeTabName: 'index',
        src: '/manager/echarts',
        key: new Date().getTime(),
        indexTab: {
          label: '首 页',
          name: 'index',
          url: '/manager/echarts',
        },
        tabs: [],
      },

      // 右侧滚动参数
      scroll: {
        // 滚动位置
        clientY: 0,
        // 透明度
        sliderTopOpacity: 0.85,
        menuSearchOpacity: 1,
      },

      // 登录用户
      user: {},

      // 配置
      config: {},

      // 收藏
      favorites: [],

      // 新手引导，参数对象
      introOption: {
        status: true,/* 是否开启引导 */
        prevLabel: '上一步',
        nextLabel: '下一步',
        skipLabel: '跳过',
        doneLabel: '完成',
        tooltipClass: 'intro-tooltip', /* 引导说明文本框的样式 */
        highlightClass: 'intro-highlight', /* 说明高亮区域的样式 */
        exitOnEsc: true, /* 是否使用键盘Esc退出 */
        exitOnOverlayClick: false, /* 是否允许点击空白处退出 */
        keyboardNavigation: true, /* 是否允许键盘来操作 */
        showBullets: false, /* 是否使用点显示进度 */
        showProgress: false, /* 是否显示进度条 */
        scrollToElement: true, /* 是否滑动到高亮的区域 */
        overlayOpacity: 0.5, // 遮罩层的透明度 0-1之间
        positionPrecedence: ['bottom', 'top', 'right', 'left'], /* 当位置选择自动的时候，位置排列的优先级 */
        disableInteraction: false, /* 是否禁止与元素的相互关联 */
        hidePrev: true, /* 是否在第一步隐藏上一步 */
        //hideNext: true, /* 是否在最后一步隐藏下一步 */
        steps: [
          {
            title: '框架--功能菜单引导',
            element: '#step1',
            intro: `登录用户信息部分<p>1、公司商标<br> 2、登录用户头像及昵称<br>3、安全退出<br>清空本地驻留信息，以确保登录用户信息安全</p>功能菜单部分<p>1、查询<br>用于快速定位并打开服务<br> 2、排序<br>对服务导航菜单进行排序<br>3、置顶<br>快速定位到功能菜单顶部，底部按钮功能相同<br>4、收藏夹<br>快速定位功能区主菜单收藏的服务<br>5、功能菜单树形结构<br>本系统服务功能导航，限于角色功能不尽相同</p> `
          },
          {
            title: '框架--常用功能引导',
            element: '#step2',
            intro: `公司欢迎信息<p>1、平台简介及欢迎<br>仅限于公司信息说明，无具体功能</p>常用功能部分<p>1、开发接口管理<br>用于开发人员接口对接及调试等<br>2、登录用户头像及昵称<br> 3、资料编辑<br>用于编辑登录用户资料、上传头像等<br> 4、密码修改 <br> 5、服务引导<br>重新查看引导流程<br> 6、安全退出<br>清空本地驻留信息，以确保登录用户<br>信息安全</p>`,
          },
          {
            title: '框架--任务栏引导',
            element: '#step3',
            intro: '软件说明<p>1、软件版本<br> 2、出品公司<br> 3、技术支持</p>网站引导<p>1、官方网站链接<br>2、平台配置，调整公司商标及名称</p><p>框架服务引导完毕，谢谢！</p>',
          },
        ], /* steps步骤，可以写个工具类保存起来 */
      },

    }
  },
  created() {

    // 初始化
    this.initParm();
    this.initCaption();

  },
  mounted() {

    // 全局
    window.openDialog = this.openDialog;
    window.closeDialog = this.closeDialog;

    window.refreshIframe = this.refreshIframe;
    window.refreshIframeCaption = this.refreshIframeCaption;
    window.getSeekPo = this.getSeekPo;
    window.deepSeek = this.deepSeek;
    window.getFavorites = this.getFavorites;
    window.showTagsByFavorites = this.showTagsByFavorites;

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }

    // 调用新手引导的方法
    this.$nextTick(() => {

      let localStorage = window.localStorage;
      if ((localStorage.getItem('isFirst') === null || localStorage.getItem('isFirst') !== '1') && this.introOption.status) {
        this.initGuide();
        localStorage.setItem('isFirst', 1);
      }

    })

  },
  methods: {

    // 初始化
    initParm() {

      // 标题
      document.title = "后台管理系统";
      this.setResize();
    },

    // 初始化数据格式
    initCaption() {

      getLoginUser().then(res => {
        this.user = res.sysUser;
        this.$forceUpdate();
      });

      getConfig().then(res => {
        this.config = res;
        this.$forceUpdate();
      });

    },

    // 切换功能菜单
    showTags(data) {

      // 时间戳
      this.menuTabs.key = new Date().getTime();

      // iframe路径
      let vueComponent = data["vueComponent"];
      if (!vueComponent) vueComponent = data["url"];

      if (data.isIndex == true) {
        this.menuTabs.indexTab.url = vueComponent;
        data["id"] = "index";
      } else {
        // 添加选项标签，不能添加重复的
        let ret = this.menuTabs.tabs.filter((tab) => (tab.name == data["id"]));
        if (ret.length == 0) {
          this.menuTabs.tabs.push({
            title: data["title"],
            name: data["id"],
            url: vueComponent
          });
          //this.$message({type: 'warning', message: '激活' + data["title"] + '服务！'});
        }
      }

      // 页面载入+设置活动选项
      this.menuTabs.src = vueComponent;
      this.menuTabs.activeTabName = data["id"];

      // 移动端时关闭功能菜单
      let width = document.documentElement.clientWidth;
      if (width < 768) {
        this.sliderMenu.showSliderMenu = false;
      }

    },

    // 由收藏激发
    showTagsByFavorites(data) {

      this.showTags(data);

      // 左侧设置活动菜单
      this.$refs.menubar.showActiveByName(data.id);

    },

    // 切换选项标签
    clickTab(tab, event) {
      // 时间戳+地址
      this.menuTabs.key = new Date().getTime();
      this.menuTabs.src = tab.$attrs.url

      // 左侧设置活动菜单
      this.$refs.menubar.showActiveByName(tab.name);
    },

    // 删除选项标签
    removeTab(targetName) {

      let tabs = this.menuTabs.tabs;
      let activeName = this.menuTabs.activeTabName;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              // 时间戳+地址
              this.menuTabs.key = new Date().getTime();
              this.menuTabs.src = nextTab.url
              activeName = nextTab.name;
            }
          }
        });
      }

      // 左侧设置活动菜单
      this.$refs.menubar.showActiveByName(activeName);
      this.menuTabs.activeTabName = activeName;
      this.menuTabs.tabs = tabs.filter(tab => tab.name !== targetName);
    },

    // 导航之用户管理菜单
    userMenu(key) {
      if (key == "edit-user") {
        this.openDialog("编辑资料", "#/manager/updateUser");
      } else if (key == "edit-password") {
        this.openDialog("密码修改", "#/manager/updatePwd");
      } else if (key == "intro") {
        this.initGuide();
      } else if (key == "logout") {
        this.quit();
      }
    },

    // 功能菜单开关
    sliderMenuSwitch() {
      this.sliderMenu.showSliderMenu = !this.sliderMenu.showSliderMenu;
    },

    // 菜单查询
    menuSearch() {

      let seekValue = this.sliderMenu.menuSearchValue;
      let element;
      let type = 'warning';
      if (seekValue) {
        element = document.querySelector("li.el-menu-item[title*='" + seekValue + "']");
      } else {
        this.$message({type: type, message: "请输入查询内容！"});
        return;
      }

      if (element != undefined) {
        let id = element.getAttribute('id');
        let data = {
          title: element.getAttribute('title'),
          prefix: element.getAttribute('prefix'),
          id: id,
          url: element.getAttribute('url'),
          uniappComponent: element.getAttribute('uniappcomponent'),
          vueComponent: element.getAttribute('vuecomponent'),
        };
        this.showTags(data);
        this.$refs.menubar.showActiveByName(id);
      } else {
        this.$message({type: type, message: "未查询到功能！"});
      }

    },

    // 检测窗体变化
    setResize() {
      this.$nextTick(() => {
        let width = document.documentElement.clientWidth;
        if (width < 768) {
          this.sliderMenu.showSliderMenu = false;
          this.introOption.status = false;
        } else {
          this.sliderMenu.showSliderMenu = true;
          this.introOption.status = true;
        }
      });
    },

    // 功能菜单滚动，待扩展
    scrollSliderMenu(e) {

      // 延时方式判断是否结束
      // 在滚动时，让上方固定元素半透明
      //console.log("滚动",e);
      // 设置透明度

      /*
      let val = 0.15;
      this.scroll.sliderTopOpacity = 0.85-val;
      this.scroll.menuSearchOpacity = 1-val;

      //延时执行后当newValue等于window.scrollY，代表滚动结束
      setTimeout(() => {
        if(this.scroll.clientY != e.clientY) {
          this.scroll.clientY = e.clientY;
        }else{
          // 还原透明度
          this.scroll.sliderTopOpacity = 0.85;
          this.scroll.menuSearchOpacity = 1;
        }
      }, 800);
      */

    },

    // 打开次级窗体
    openDialog(title, linkUrl) {
      this.dialog.key = new Date().getTime();
      this.dialog.slaveTitle = title;
      this.dialog.slaveSrc = linkUrl;
      this.dialog.slaveVisible = true;
    },

    // 关闭次级窗体
    closeDialog() {
      this.dialog.slaveVisible = false;
    },

    // 刷新嵌入窗体
    refreshIframe() {

      // 获取iframe元素
      let iframe = this.$refs.mainIframe;

      // 在iframe中调用方法
      iframe.contentWindow.initList();
    },

    // 刷新头文件
    refreshIframeCaption() {

      // 获取iframe元素
      let iframe = this.$refs.mainIframe;

      // 在iframe中调用方法
      iframe.contentWindow.initCaption();
    },

    // 获得当前查询条件
    getSeekPo() {
      return this.$refs["mainIframe"].contentWindow.getSeekPo();
    },

    // 深度查询
    deepSeek(condition, conditionAttach) {
      return this.$refs["mainIframe"].contentWindow.deepSeek(condition, conditionAttach);
    },

    // 退出
    quit() {
      logout().then(res => {
        removeToken();
        this.$router.push("/login");
      });
    },

    // 左侧功能菜单查询
    leftMenu(command) {

      let act = command['act'];
      if (act == "menuSearch") {
        this.menuSearch();
      } else if (act == "sort") {
        this.$refs.menubar.setSortOrder();
      } else if (act == "backtop") {
        this.scrollTop();
      } else if (act == "favorites") {
        this.showFavorites();
      }

    },

    // 初始化新手引导
    initGuide() {
      let intro = this.$intro();
      intro.setOptions(this.introOption);
      intro.onbeforechange((targetElement) => {
        console.log('点击下一步按钮前执行的事件')
      });
      intro.oncomplete(() => {
        console.log('点击结束按钮后执行的事件')
      });
      intro.onexit(() => {
        console.log('点击跳过按钮后执行的事件')
      });
      intro.onbeforeexit(() => {
        console.log('确认完毕之后执行的事件')
      });
      intro.start();
    },

    // 滚动到顶部
    scrollTop() {
      this.$refs.scrollSliderMenu.scrollTop = 0;
    },

    // 显示收藏夹
    showFavorites() {

      let localStorage = window.localStorage;
      if (localStorage) {
        let local = localStorage.getItem("favorites");
        if (local) {
          let localArr = JSON.parse(local);
          let elements = document.querySelectorAll(".slider-menu-bar li[role=menuitem]");

          for (let i in elements) {

            let element = elements[i];

            if (!element) continue;
            if (typeof element != "object") continue;

            let id = element.getAttribute('id');
            let vueComponent = element.getAttribute('vuecomponent');
            if (!localArr.includes(id)) continue;
            if (!vueComponent) continue;

            let ret = this.favorites.findIndex((item) => {
              return item.id == id;
            });

            if (ret == -1) {
              this.favorites.push({
                id: id,
                title: element.getAttribute('title'),
                prefix: element.getAttribute('prefix'),
                url: element.getAttribute('url'),
                uniappComponent: element.getAttribute('uniappcomponent'),
                vueComponent: vueComponent,
              });
            }

          }

          // 载入到窗体，并点击
          //console.log("收藏夹", this.favorites);
          this.openDialog("我的收藏", "#/manager/showFavoritesMain");

        }
      }
    },

    // 获得收藏夹
    getFavorites() {

      return this.favorites;
    },

    // 打开接口管理
    openApi() {
      //window.open(this.apiUrl + "/doc.html#/documentManager/OfficelineDocument-业务端接口服务");
      this.openDialog("接口管理", this.apiUrl + "/doc.html#/documentManager/OfficelineDocument-业务端接口服务");
    },

    // 数字互联
    openLink() {
      let linkUrl = "#/manager/showImageDetail?url=vue/getQrcode" + "&from=index&to=detail";
      this.openDialog("数字互联", linkUrl);
    },

    // app下载
    downApp() {
      let linkUrl = "#/manager/showImageDetail?url=vue/downAndroidApp" + "&from=index&to=detail"
      this.openDialog("立即下载", linkUrl);
    },
  },

}
</script>

<style lang="scss">

/*--基础样式--*/
html, body, #app, .layout {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #FFF;
}

/*--滚动条--*/
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-track-piece {
  background-color: #F5F5F5;
  -webkit-border-radius: 0px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 0px;
  background-color: #999999;
  -webkit-border-radius: 5px;
  background-color: rgba(153, 153, 153, 0.2);
}

::-webkit-scrollbar-thumb:horizontal {
  width: 0px;
  background-color: #999;
  -webkit-border-radius: 0px;
  background-color: rgba(153, 153, 153, 0.2);
}

/*--浮动位置--*/
.float-left {
  float: left;
}

.float-right {
  float: right;
}

/*--进度条--*/
/*
#nprogress .bar {
  background: #F56C6C;
}
*/

.layout {
  position: relative;
}

/*--左侧菜单栏--*/
.slider-menu {

  position: fixed;
  z-index: 10;
  width: 220px;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #2f4050;

  .slider-menu-top {

    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 5;
  }

  .slider-menu-bar {

    width: 100%;
    height: 100%;
    overflow-y: auto;

    .slider-menu-grap {
      width: 100%;
      height: 155px;
    }

    .slider-menu-scroll-top {
      position: absolute;
      bottom: -14px;
      left: calc(50% - 14px);

      width: 30px;
      height: 28px;
      line-height: 20px;
      border-radius: 28px;
      text-align: center;

      background: #fdf6ec;
      border-color: #f5dab1;
      opacity: 0.85;

      i {
        font-size: 16px;
        color: #E6A23C;
      }
    }
  }

  .el-divider--horizontal {
    margin: 10px 0px;
  }

  .el-divider__text {
    position: absolute;
    background-color: #293846;
    padding: 0px 10px;
    font-weight: 500;
    color: #FFF;
  }

  /*--公司图标367fa9 5695ca--*/
  .logo {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #367fa9;
    text-align: center;
    border-right: 1px solid #58abdc;

    img {
      vertical-align: middle;
      padding: 0px;

      max-width: 220px;
      max-height: 40px;

      /*
      opacity: 0.85;
      background-color: #fff;
      */
    }

    .slider-menu-close {
      display: none;
      position: absolute;
      z-index: 1;
      top: 0px;
      right: 10px;
      height: 100%;

      font-size: 1.4em;
      color: rgba(255, 255, 255, .3);
    }
  }

  /*--登录者信息--*/
  .login-user-info {

    font-size: 12px;
    color: #FFF;
    padding: 5px 0px;
    background: #293846;

    /*
    background-image: url("../../static/images/23.jpg");
    */

    .user-header {
      text-align: center;

      img {
        width: 45px;
        margin: 8px auto;
        border-radius: 45px;
        vertical-align: middle;
      }
    }

    .user-info-label {

      div:nth-child(1) {
        font-weight: bold;
        letter-spacing: 1px;
        padding: 10px 0px 6px 0px;
      }

      i {
        color: #F56C6C;
      }
    }
  }

  /*--菜单检索--*/
  .menu-search {

    padding: 0px 2px;
    font-size: 12px;
    background: #293846;

    button:nth-child(1) {
      padding: 7px 0px;
      padding-left: 8px;
    }

    button:nth-child(2) {
      padding: 7px 2px;
    }

    i {
      color: #909399 !important;
    }

    .i-second {
      margin-left: 5px;
    }

    .el-dropdown .el-dropdown__caret-button::before {
      content: none !important;
    }

    .el-input--small .el-input__inner, .el-input--small .el-input__inner:focus {
      height: 30px;
      line-height: 32px;
      border-color: #DCDFE6;
      font-weight: 100;
    }

    .el-input-group__append, .el-input-group__prepend {
      padding: 0 10px;
    }

  }

  /*--取消菜单有边框--*/
  .el-menu {
    border-right: 0px !important;
  }

}

/*--右侧页面--*/
.page-wrapper {

  position: inherit;
  margin: 0 0 0 220px;
  height: 100%;
  overflow: hidden;

  /*--上部工具按钮--*/
  .navbar {

    position: relative;
    height: 50px;
    line-height: 50px;
    padding-right: 10px;
    background-color: #3c8dbc;
    overflow: hidden;
    /*
    background-image: url("../../static/images/delicious_2.png");
    */

    height: 50px;
    line-height: 50px;
    color: #FFF;
    font-size: 14px;
    text-align: center;

    /*--左侧按钮--*/
    .navbar-left-button {

      width: auto;
      float: left;
      height: 50px;
      line-height: 50px;
      margin-right: 0px;
      border-right: 1px solid #58abdc;
    }

    /*--功能菜单开关--*/
    .slider-menu-open {
      display: none;
      width: 50px !important;
    }

    /*--欢迎条--*/
    .welcome {
      width: auto;
      padding-left: 30px;
      padding-right: 30px;
      color: #FFF;
      font-size: 14px;
      border-left: 1px solid #58abdc;
      overflow: hidden;
      letter-spacing: 1px;

    }

    /*--右侧按钮--*/
    .navbar-right-button {

      width: auto;
      float: right;
      height: 50px;
      line-height: 50px;
      margin-left: 0px;
      border-left: 1px solid #58abdc;
      color: #FFF;

      /*--头像--*/
      .navbar-user-header {

        text-align: center;
        border-right: 1px solid #58abdc;
        display: inline-block;
        margin-right: 5px;
        /*
        background-color: #367fa9;
        background-image: url("../../static/images/24.jpg");
        */

        img {
          width: 28px;
          height: 28px;
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 28px;
          vertical-align: middle;
          text-align: center;
        }
      }

      .navbar-user-title {
        color: #FFF;
      }

    }

    .full-button {
      padding-left: 20px;
      padding-right: 20px;
    }

    .full-button:hover {
      background-color: #367fa9;
    }
    .navbar-user-header:hover{
      background-color: #367fa9;
    }

  }

  /*--选项卡--*/
  .tab-wrapper {

    .el-tabs__nav-wrap {
      background-image: url("../../static/images/n.png");
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
      background-size: 16px;
    }

    .el-tabs__nav-wrap.is-scrollable {
      background-image: none;
    }

    .el-tabs__header {
      margin: 0px;
    }

    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {

      background-image: linear-gradient(to top, #4094ff 5%, #F5F5F5 10%);
      opacity: 0.8;
    }

    .el-tabs--border-card > .el-tabs__content {
      padding: 0px;
    }

    .el-tabs__item {
      opacity: 0.65;
    }

  }

  /*--嵌入页面--*/
  .main-iframe {

    border: 0px;
    width: 100%;
    display: inline;
    overflow: hidden;

    height: calc(100% - 125px);
    background: url(../../static/images/bodyback.jpg);
  }

  /*--版本--*/
  .version-wrapper {

    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 220px;

    height: 36px;
    line-height: 36px;
    padding: 0px 8px;
    font-size: 13px;

    background: url(../../static/images/bodyback.jpg);

    .version {

      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 8px;
      right: 8px;
      padding-right: 10px;
      text-align: right;

      background: #FFF;
      color: rgba(0, 0, 0, .45);

      box-shadow: 0px -1px 3px rgba(0, 0, 0, .2);
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      width: auto;
    }
  }

}

/*--用户下拉菜单--*/
.el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
  min-width: 155px !important;
  padding: 0px !important;
  opacity: 0.95;
}

.user-menu-item {
  opacity: 0.9;

  i {
    color: #FFF !important;
    margin-left: 10px;
    width: 20px;
  }
}

/*--弹出窗体--*/
.el-dialog {
  margin-top: 40px !important;
  height: calc(100% - 80px);
  margin-left: 220px !important;
  width: calc(100% - 440px);
}

.el-dialog__wrapper {

  overflow: hidden;

  .el-dialog__header {
    padding: 8px 20px;
    border-bottom: 1px solid #E4E7ED;
    background-color: #F5F5F5 !important;

    .el-dialog__title {
      margin-left: 5px;
      font-size: 14px !important;
      color: #606266 !important;
    }

    .el-dialog__headerbtn {
      top: 12px;
      right: 15px;
    }
  }

  .el-dialog__header:before {
    content: "\f17a";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px !important;
    color: #606266;
  }

  .el-dialog__body {
    padding: 0px;
    height: calc(100% - 40px);
    overflow: hidden;
    border: 1px solid #F5F5F5;
    border-top: 0;

    .slave-iframe {
      margin: 0px;
      padding: 0px;
      width: 100%;
      height: 100%;
    }
  }
}


/*--新手引导样式--*/
.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 0px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
  border: 3px dashed #409eff;
}

.new-tips {
  color: #409eff;
  line-height: 80px;
  cursor: pointer;
}

.introjs-tooltip-title {
  font-size: 14px;
  width: 80%;
  padding-top: 10px;
}

.warper {
  width: 200px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid saddlebrown;
}

/* 重置引导组件样式(类似element-ui个人使用) */
.intro-tooltip {
  color: #ffff;
  background: #2c3e50;
}

/* 引导提示框的位置 */
.introjs-bottom-left-aligned {
  left: 45% !important;
}

.introjs-right,
.introjs-left {
  top: 30%;
}

.intro-highlight {
  background: rgba(255, 255, 255, 0.5);
}

.introjs-arrow.left {
  border-right-color: #2c3e50;
}

.introjs-arrow.top {
  border-bottom-color: #2c3e50;
}

.introjs-arrow.right {
  border-left-color: #2c3e50;
}

.introjs-arrow.bottom {
  border-top-color: #2c3e50;
}

/* 提示框头部区域 */
.introjs-tooltip-header {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-left: 10px !important;
}

.introjs-skipbutton {
  color: #409eff !important;
  font-size: 14px !important;
  font-weight: normal !important;
  //   padding: 8px 10px !important ;
  height: 31px;
  line-height: 31px;
}

.introjs-tooltipbuttons {
  border: none !important;
}

.introjs-tooltiptext {
  font-size: 14px !important;
  padding: 10px !important;
  line-height: 24px;
}

/* 提示框按钮 */
.introjs-tooltipbuttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.introjs-button {
  width: 50px !important;
  text-align: center;
  padding: 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 3px !important;
  border: none !important;
}

.introjs-button:last-child {
  margin-left: 10px;
}

.introjs-prevbutton {
  color: #606266 !important;
  background: #fff !important;
  border: 1px solid #dcdfe6 !important;
}

.introjs-nextbutton {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

.introjs-disabled {
  color: #9e9e9e !important;
  border-color: #bdbdbd !important;
  background-color: #f4f4f4 !important;
}

/*--响应式--*/
@media (max-width: 768px) {

  .page-wrapper {
    margin: 0px;
  }
  .tab-wrapper, .navbar-right-button, .navbar-left-button {
    display: none;
  }
  .slider-menu-close, .slider-menu-open, .navbar-user-menu,.user-top-menu {
    display: block !important;
  }

  .version-wrapper {
    left: 0px !important;

    .version {
      text-align: center !important;

      span{
        display: none;
      }
    }
  }

  .main-iframe {
    height: calc(100% - 83px) !important;
  }

  .el-dialog {
    margin-top: 0px !important;
    height: 100%;
    margin-left: 0px !important;
    width: 100%;
  }
}

</style>