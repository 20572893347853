<template>

  <el-menu
      class="el-menu-vertical"
      :default-active="activeMenu"
      @open="handleOpen"
      @close="handleClose"
      @select="handSelect"
      unique-opened
      mode="vertical"
      background-color="#293846"
      text-color="#a7b1c2"
      active-text-color="#FFF">
    <template v-for="menu in menuData">
      <MenuTree v-if="menu.children.length > 0" :menu="menu" @showActive="showActive"></MenuTree>
    </template>
  </el-menu>

</template>

<script>

import MenuTree from './menutree'
import {menu} from '@/api/public'

export default {
  name: "Menubar",
  components: {MenuTree},
  data() {
    return {
      menuData: [],
      isInitActiveMenu: false,
      activeMenu: '',
      sortOrder: 'asc',
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
    // 点击菜单
    handSelect(key, keyPath, element) {

      let data = {
        title: element.$attrs.title,
        prefix: element.$attrs.prefix,
        id: element.$attrs.id,
        url: element.$attrs.url,
        uniappComponent: element.$attrs.uniappComponent,
        vueComponent: element.$attrs.vueComponent,
      };

      localStorage.setItem('activeMenu',JSON.stringify(data));

      this.$emit('showTags', data);
    },
    // 初始化功能菜单
    menu() {
      menu(this.sortOrder).then(res => {
        this.menuData = res;
        this.$forceUpdate();
      });
    },
    // 初始化首页，第一个正常功能
    showActive(menu) {

      if (!this.isInitActiveMenu) {
        this.isInitActiveMenu = true;

        let data={};

        // 获取缓存菜单
        const cacheMenu = localStorage.getItem('activeMenu');
        if (cacheMenu) {
          data = JSON.parse(cacheMenu);
          data["isIndex"] = true;
          this.activeMenu = data.id;
        }else{
          data = {
            title: menu.nodeName,
            prefix: menu.prefix,
            id: menu.nodeId,
            url: menu.nodeUrl,
            uniappComponent: menu.uniappComponent,
            vueComponent: menu.vueComponent,
            isIndex: true,
            parentId: menu.parentId,
          };
          this.activeMenu = menu.nodeId;
        }

        this.$emit('showTags', data);
      }

    },

    // 由活动标签设置活动菜单
    showActiveByName(activeMenu) {
      this.activeMenu = activeMenu;
    },

    // 变更排序方式
    setSortOrder() {

      this.sortOrder = this.sortOrder=='asc'?'desc':'asc';
      this.menu();
    }
  },
  // 组件创建时
  created() {
    this.menu();
  }
}
</script>

<style>

.el-submenu .el-menu-item {
  height: 40px;
  line-height: 40px;
  padding: 0px;
}

.el-menu-item, .el-submenu__title {
  height: 50px;
  line-height: 50px;
}

.el-menu-item:hover, .el-submenu__title:hover {
  color: #FFF !important;
}

li[aria-expanded=true] .el-submenu__title {
  color: #FFF !important;
}

</style>